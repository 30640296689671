import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { TextField } from '@mui/material';
import { IMaskInput } from 'react-imask'

const NUMBER_REGEX = /^[0-9]*\.?[0-9]*$/

const Masked = React.forwardRef(({ inputRef, decimalLimit, padFractionalZeros, onChange, ...props }, ref) => (
  <IMaskInput
    {...props}
    inputRef={inputRef}
    mask='num'
    unmask
    blocks={{
      num: {
        mask: Number,
        thousandsSeparator: ',',
        scale: decimalLimit || 0,
        padFractionalZeros:
        padFractionalZeros !== false
            ? padFractionalZeros || decimalLimit > 0
            : false,
        normalizeZeros: false,
        radix: '.'
      }
    }}
    onAccept={(v, masked) => {
      onChange({ target: { value: v } }, v, masked)
    }}
/>
))

export const NumberInput = React.forwardRef(
  (
    { value, onChange, inputProps, decimalLimit, padFractionalZeros, onBlur, helperText, error, ...props },
    ref
  ) => {
    const [hasError, setHasError] = useState(false)

    useEffect(() => {
      if (NUMBER_REGEX.test(value)) {
        setHasError(false)
      }
    }, [value])

    const handleBlur = (e) => {
      if (!NUMBER_REGEX.test(value)) {
        setHasError(true)
      }
      onBlur(e)
    }

    return (
      <TextField
        {...props}
        error={hasError || error}
        helperText={hasError || error ? helperText : undefined}
        value={value}
        onChange={onChange}
        onBlur={handleBlur}
        InputProps={{
          inputComponent: Masked,
          inputProps: {
            decimalLimit,
            padFractionalZeros
          },
          ...inputProps,
        }}
        ref={ref}
      />
    )
  }
)

NumberInput.displayName = 'NumberInput'

NumberInput.defaultProps = {
  value: '',
  onChange: () => null,
  onBlur: () => null,
  variant: 'outlined',
  size: 'small',
  helperText: 'Please enter a number'
}

NumberInput.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  inputProps: PropTypes.object,
  error: PropTypes.bool,
  helperText: PropTypes.string,
  decimalLimit: PropTypes.number,
  padFractionalZeros: PropTypes.bool,
}

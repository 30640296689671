import axios from 'axios';

export const getFormData = async (id) => {
  const res = await axios.get(
    `${process.env.REACT_APP_API_URL}intake-form?id=${id}`
  );
  return res.data;
};

export const submitForm = async (body) => {
  const res = await axios.post(
    `${process.env.REACT_APP_API_URL}intake-form`,
    body,
  );
  return res.data;
};

export const patchForm = async (id, body) => {
  const res = await axios.patch(
    `${process.env.REACT_APP_API_URL}intake-form?id=${id}`,
    body,
  );
  return res.data;
};

export const getUploadAttachments = async (id) => {
  const res = await axios.get(
    `${process.env.REACT_APP_API_URL}intake-form-attachments?id=${id}`
  );
  return res.data;
};

export const uploadAttachments = async (id, formData) => {
  const res = await axios.post(
    `${process.env.REACT_APP_API_URL}intake-form-attachments?id=${id}`,
    formData,
  );
  return res.data;
};
import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { TextField } from '@mui/material'
import { IMaskInput } from 'react-imask'

const PHONE_REGEX = /^\d{3}-\d{3}-\d{4}(?:\s\d{1,4})?$/

const Masked = React.forwardRef(({ inputRef, onChange, ...props }, ref) => (
  <IMaskInput
    {...props}
    inputRef={inputRef}
    mask='000-000-0000[ extension]'
    blocks={{
      extension: {
        mask: Number,
        min: 0,
        max: 9999
      }
    }}
    onAccept={(v, masked) => {
      onChange({ target: { value: v } }, v, masked)
    }}
  />
))

export const PhoneInput = React.forwardRef(
  (
    { value, onChange, inputProps, onBlur, helperText, error, ...props },
    ref
  ) => {
    const [hasError, setHasError] = useState(false)

    useEffect(() => {
      if (PHONE_REGEX.test(value)) {
        setHasError(false)
      }
    }, [value])

    const handleBlur = (e) => {
      if (value && !PHONE_REGEX.test(value)) {
        setHasError(true)
        if (value.endsWith(' ')) {
          onChange({ target: { value: value.trim() } })
        }
      }
      onBlur(e)
    }

    return (
      <TextField
        {...props}
        error={hasError || error}
        helperText={hasError || error ? helperText : undefined}
        value={value}
        onChange={onChange}
        onBlur={handleBlur}
        InputProps={{
          inputComponent: Masked,
          inputProps
        }}
        ref={ref}
      />
    )
  }
)

PhoneInput.displayName = 'PhoneInput'

PhoneInput.defaultProps = {
  value: '',
  onChange: () => null,
  onBlur: () => null,
  variant: 'outlined',
  size: 'small',
  helperText: 'Please enter a valid phone number'
}

PhoneInput.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  inputProps: PropTypes.object,
  error: PropTypes.bool,
  helperText: PropTypes.string
}

import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Radio,
  RadioGroup,
} from '@mui/material';
import clsx from 'clsx';
import React from 'react';

export const RadioInput = ({
  id,
  fullWidth,
  error,
  helperText,
  label,
  className,
  disabled,
  value,
  onChange,
  onBlur,
  options = [],
  row,
}) => {
  return (
    <FormControl
      id={id}
      component='fieldset'
      size='small'
      fullWidth={fullWidth}
      error={error}
      className={clsx(className, disabled && 'disabled')}
    >
      {label && <FormLabel component='legend'>{label}</FormLabel>}
      <RadioGroup
        value={value ?? ''}
        onChange={(e) => {
          onChange(e, e.target.value);
        }}
        onBlur={onBlur}
        row={row}
      >
        {options.map((x) => {
          return (
            <FormControlLabel
              key={x.value}
              value={x.value ?? ''}
              label={x.text}
              control={
                <Radio color='secondary' disabled={x.disabled || disabled} />
              }
              disabled={x.disabled || disabled}
            />
          );
        })}
      </RadioGroup>
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
};

import { AgentSection, AttachmentSection, BuyerSection, PropertySection, SellerSection } from 'components/LeadsForm/Sections';
import './App.css';
import { LeadsForm } from './components/LeadsForm'
import { Route, BrowserRouter, Routes } from "react-router-dom"

export const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/:id?" element={<LeadsForm />}>
          <Route index element={<PropertySection />} />
          <Route path="property" element={<PropertySection />} />
          <Route path="seller" element={<SellerSection />} />
          <Route path="agent" element={<AgentSection />} />
          <Route path="buyer" element={<BuyerSection />} />
          <Route path="attachments" element={<AttachmentSection />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}
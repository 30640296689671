import {
  ConnectedPlainInput,
  ConnectedPhoneNumberInput,
  ConnectedAddressInput,
  ConnectedRadioInput,
  ConnectedEmailInput,
  ConnectedNumberInput,
  ConnectedCurrencyInput,
  yesNoOptions
} from 'components/ConnectedInputs';
import {
  InputAdornment
} from '@mui/material';
import { useWatch } from 'react-hook-form';
import '../form.css';

export const BuyerSection = () => {
  const isApproved = useWatch({name: 'buyer.isApproved'});

  return (
    <>
      <h3>Buyer information</h3>
      <div className='form-input'>
        <ConnectedPlainInput
          name='buyer.name'
          label='Name'
        />
      </div>
      <div className='form-input'>
        <ConnectedEmailInput
          name='buyer.emailAddress'
          label='Email address'
        />
      </div>
      <div className='form-input'>
        <ConnectedPhoneNumberInput
          name='buyer.phoneNumber'
          label='Phone number'
        />
      </div>
      <div className='form-input'>
        <ConnectedNumberInput
          name='buyer.timeInHome'
          label='Time in home'
          options={yesNoOptions}
          inputProps={{
            endAdornment: (
              <InputAdornment position='end'>years</InputAdornment>
            ),
          }}
        />
      </div>
      <h3>Buyer address</h3>
      <div>
        <ConnectedAddressInput
          name='buyer.address'
        />
      </div>
      <h3>Mortgage approval</h3>
      <div className='form-input'>
        <ConnectedRadioInput
          row
          name='buyer.isApproved'
          label='Approved?'
          options={yesNoOptions}
        />
      </div>
      {isApproved === 'yes' && (
        <div className='form-input'>
          <ConnectedCurrencyInput
            name='buyer.approvalAmount'
            label='Approval amount'
          />
        </div>
      )}
    </>
  )
}
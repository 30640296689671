import React from 'react';
import {
  ConnectedPlainInput,
  ConnectedSelectInput,
  ConnectedZipcodeInput,
} from 'components/ConnectedInputs';
import statesOptions from 'util/states.json';

export const ConnectedAddressInput = React.forwardRef(({name, label, required, ...props}, ref) =>
{
    const getLabel = (section) => {
      const capitalized =  section.charAt(0).toUpperCase() + section.slice(1)
      return label ? `${label} ${section}` : capitalized;
    }

    return (
      <>
        <div className='form-input'>
          <ConnectedPlainInput
            name={`${name}.street`}
            label={getLabel('address')}
            required={required}
            ref={ref}
          />
        </div>
        <div className='form-input'>
          <ConnectedSelectInput
            name={`${name}.state`}
            label={getLabel('state')}
            options={statesOptions}
            required={required}
            ref={ref}
          />
        </div>
        <div className='form-input'>
          <ConnectedPlainInput
            name={`${name}.city`}
            label={getLabel('city')}
            required={required}
            ref={ref}
          />
        </div>
        <div className='form-input'>
          <ConnectedZipcodeInput
            name={`${name}.zipcode`}
            label={getLabel('zipcode')}
            required={required}
            ref={ref}
          />
        </div>
      </>
    )
  }
)
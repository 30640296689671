import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import TextField from '@mui/material/TextField';
import { IMaskInput } from 'react-imask'

const ZIPCODE_REGEX = /^\d{5}(-\d{4})?$/

const Masked = React.forwardRef(({ inputRef, onChange, ...props }, ref) => (
  <IMaskInput
    {...props}
    inputRef={inputRef}
    mask={[
      {
        mask: '00000'
      },
      {
        mask: '00000-0000'
      }
    ]}
    onAccept={(v, masked) => {
      onChange({ target: { value: v } }, v, masked)
    }}
  />
))

export const ZipcodeInput = React.forwardRef(
  (
    { value, onChange, inputProps, onBlur, helperText, error, ...props },
    ref
  ) => {
    const [hasError, setHasError] = useState(false)

    useEffect(() => {
      if (ZIPCODE_REGEX.test(value)) {
        setHasError(false)
      }
    }, [value])

    const handleBlur = (e) => {
      if (value.length > 5 && !ZIPCODE_REGEX.test(value)) {
        setHasError(true)
      } else if (value.length && value.length < 5) {
        setHasError(true)
      }
      onBlur(e)
    }

    return (
      <TextField
        {...props}
        error={hasError || error}
        helperText={hasError || error ? helperText : undefined}
        value={value}
        onChange={onChange}
        onBlur={handleBlur}
        InputProps={{
          inputComponent: Masked,
          inputProps
        }}
        ref={ref}
      />
    )
  }
)

ZipcodeInput.displayName = 'ZipcodeInput'

ZipcodeInput.defaultProps = {
  value: '',
  onChange: () => null,
  onBlur: () => null,
  variant: 'outlined',
  size: 'small',
  helperText: 'Please enter a valid zip code'
}

ZipcodeInput.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  inputProps: PropTypes.object,
  error: PropTypes.bool,
  helperText: PropTypes.string
}

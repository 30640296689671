import { NumberInput } from 'components/BaseInputs/NumberInput';
import React from 'react';
import { Controller } from 'react-hook-form';
import PropTypes from 'prop-types';
import {
  InputAdornment
} from '@mui/material';

const NUMBER_REGEX = /^[0-9]*\.?[0-9]*$/

export const ConnectedCurrencyInput = React.forwardRef(
  ({ name, rules, required, id, ...props }, ref) => {
    return (
      <Controller
        name={name}
        rules={{
          required,
          pattern: {
            value: NUMBER_REGEX,
            message: 'Please enter a number',
          },
          ...rules,
        }}
        render={({
          field: { value, onChange, onBlur },
          fieldState: { invalid, error },
        }) => (
          
          <NumberInput
            id={id ?? name}
            fullWidth
            {...props}
            name={name}
            value={value ? String(value) : ''}
            onChange={(e) => {
              onChange(e.target.value);
            }}
            onBlur={onBlur}
            inputProps={{
              startAdornment: (
                <InputAdornment position='start'>$</InputAdornment>
              ),
            }}
            decimalLimit={2}
            ref={ref}
            error={invalid}
            helperText={error?.message}
            required={Boolean(required || rules?.required)}
          />
        )}
      />
    );
  },
);

ConnectedCurrencyInput.defaultProps = {
  decimalLimit: 2,
};

ConnectedCurrencyInput.displayName = 'ConnectedCurrencyInput';
ConnectedCurrencyInput.propTypes = {
  name: PropTypes.string.isRequired,
  rules: PropTypes.object,
  required: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  disabled: PropTypes.bool,
};
export default ConnectedCurrencyInput;

import React from 'react';
import { Controller } from 'react-hook-form';
import PropTypes from 'prop-types';
import { RadioInput } from 'components/BaseInputs/RadioInput';

export const yesNoOptions = [
  {
    text: 'Yes',
    value: 'yes',
  },
  {
    text: 'No',
    value: 'no',
  },
];

export const ConnectedRadioInput = ({
  name,
  rules,
  required,
  label,
  options,
  row,
  disabled,
  id,
  fullWidth = false,
}) => {

  return (
    <Controller
      name={name}
      rules={{
        required,
        ...rules,
      }}
      render={({
        field: { value, onChange, onBlur },
        fieldState: { invalid, error },
      }) => {
        return (
          <RadioInput
            id={id ?? name}
            error={invalid}
            helperText={invalid ? error.message : null}
            fullWidth={fullWidth}
            disabled={disabled}
            value={value}
            onChange={(e, v) => onChange(v)}
            onBlur={onBlur}
            label={label}
            row={row}
            options={options}
          />
        );
      }}
    />
  );
};

ConnectedRadioInput.displayName = 'ConnectedRadioInput';

ConnectedRadioInput.propTypes = {
  name: PropTypes.string.isRequired,
  rules: PropTypes.object,
  required: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  disabled: PropTypes.bool,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      text: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
  ),
};

import {
  ConnectedPlainInput,
  ConnectedPhoneNumberInput,
  ConnectedAddressInput,
  ConnectedRadioInput,
  ConnectedEmailInput,
  ConnectedCurrencyInput,
  yesNoOptions
} from 'components/ConnectedInputs';
import '../form.css';

export const AgentSection = () => {
  return (
    <>
      <h3>Agent contact information</h3>
      <div className='form-input'>
        <ConnectedPlainInput
          name='agent.name'
          label='Name'
        />
      </div>
      <div className='form-input'>
        <ConnectedPlainInput
          name='agent.companyName'
          label='Company name'
        />
      </div>
      <div className='form-input'>
        <ConnectedEmailInput
          name='agent.emailAddress'
          label='Email address'
        />
      </div>
      <div className='form-input'>
        <ConnectedPhoneNumberInput
          name='agent.phoneNumber'
          label='Phone number'
        />
      </div>
      <h3>Agent address</h3>
      <div>
        <ConnectedAddressInput
          name='agent.address'
        />
      </div>
      <h3>Listing</h3>
      <div className='form-input'>
        <ConnectedRadioInput
          row
          name='agent.isBuyerInPlace'
          label='Buyer in place?'
          options={yesNoOptions}
        />
      </div>
      <div className='form-input'>
        <ConnectedCurrencyInput
          name='agent.listingPrice'
          label='Listing price'
        />
      </div>
      <div className='form-input'>
        <ConnectedPlainInput
          name='agent.brokerArvOpinion'
          label='Broker ARV opinion'
        />
      </div>
    </>
  )
}
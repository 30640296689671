import React, { useState, useEffect } from 'react'
import {useDropzone} from 'react-dropzone'
import { Link, CircularProgress } from '@mui/material';
import { getUploadAttachments, uploadAttachments } from 'actions/formActions';
import { useParams  } from "react-router-dom"
import '../form.css';

function MyDropzone({id, onUploaded}) {
  const [loading, setLoading] = useState(false);
  const maxSize = 1048576 * 20;

  const uploadFiles = async (acceptedFiles) => {
    setLoading(true);
    let formData = new FormData();
    for (var i = 0; i < acceptedFiles.length; i++) {
        let file = acceptedFiles[i];
        formData.append('attachments[]', file);
    }
    await uploadAttachments(id, formData);
    if (onUploaded) {
      onUploaded();
    }
    setLoading(false);
  }

  const {getRootProps, getInputProps} = useDropzone(
    {
      onDrop: uploadFiles,
      maxSize,
      accept: {
        'image/png': ['.png'],
        'image/jpg': ['.jpg', '.jpeg'],
        'application/pdf': ['.pdf'],
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],

      }
    }
  );

  return (
    <section>
      {loading ? (
        <CircularProgress size={20} />
      ) : (
        <div {...getRootProps({className: 'dropzone'})}>
          <input {...getInputProps()} />
          <p>Drag 'n' drop some files here, or click to select files</p>
          <em>(Only *.jpg, *.png, *.pdf, *.xlsx, *.docx up to 20mb will be accepted)</em>
        </div>
      )}
    </section>
  );
}

export const AttachmentSection = () => {
  const {id} = useParams();
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [loading, setLoading] = useState(false);

  const fetchUploadedAttachments = async () => {
    if (id) {
      setLoading(true);
      const attachments = await getUploadAttachments(id);
      setUploadedFiles(attachments);
      setLoading(false);
    }
  }

  useEffect(() => {
      if (id) {
        fetchUploadedAttachments();
      }
  // eslint-disable-next-line
  }, [id])

  return (
    <>
      {id && (
        <>
          <h3>Upload attachments</h3>
          <MyDropzone id={id} onUploaded={fetchUploadedAttachments} />
          <h3>Uploaded attachments</h3>
          {loading && (
            <CircularProgress size={20} />
          )}
          {!loading && uploadedFiles?.length === 0 && (
            <b>No files found</b>
          )}
          {(uploadedFiles && uploadedFiles.length > 0) && (
            <>

              <ul>
                {uploadedFiles.map(file => 
                    <li key={file.fileName}><Link href={file.url}>{file.fileName}</Link></li>
                )}
              </ul>
            </>
          )}

        </>
      )}
    </>
  )
}
import {
  ConnectedPlainInput,
  ConnectedPhoneNumberInput,
  ConnectedAddressInput,
  ConnectedRadioInput,
  ConnectedEmailInput,
  ConnectedNumberInput,
  ConnectedCurrencyInput,
  yesNoOptions
} from 'components/ConnectedInputs';
import {
  InputAdornment
} from '@mui/material';
import { useWatch } from 'react-hook-form';
import '../form.css';

export const SellerSection = () => {
  const isListed = useWatch({name: 'seller.isHomeListed'});

  return (
    <>
      <h3>Seller information</h3>
      <div className='form-input'>
        <ConnectedPlainInput
          name='seller.name'
          label='Name'
        />
      </div>
      <div className='form-input'>
        <ConnectedEmailInput
          name='seller.emailAddress'
          label='Email address'
        />
      </div>
      <div className='form-input'>
        <ConnectedPhoneNumberInput
          name='seller.phoneNumber'
          label='Phone number'
        />
      </div>
      <div className='form-input'>
        <ConnectedNumberInput
          name='seller.timeInHome'
          label='Time in home'
          inputProps={{
            endAdornment: (
              <InputAdornment position='end'>years</InputAdornment>
            ),
          }}
        />
      </div>
      <h3>Seller address</h3>
      <div>
        <ConnectedAddressInput
          name='seller.address'
        />
      </div>
      <h3>Listing</h3>
      <div className='form-input'>
        <ConnectedRadioInput
          row
          name='seller.isHomeListed'
          label='Is home listed?'
          options={yesNoOptions}
        />
      </div>
      {isListed === 'yes' && (
        <div className='form-input'>
          <ConnectedCurrencyInput
            name='seller.listingPrice'
            label='Listing price'
          />
        </div>
      )}
      <div className='form-input'>
        <ConnectedRadioInput
          row
          name='seller.anyOpenPermits'
          label='Any open permits?'
          options={yesNoOptions}
        />
      </div>
    </>
  )
}
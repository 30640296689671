import {Grid} from '@mui/material';
import logo from 'images/logo.png';
import './form.css';

export const Header = () => {
  return (
    <Grid container spacing={2} alignItems="flex-end">
      <Grid item xs={4}>
        <a href="https://www.springfieldhomesusa.com">
          <img className="app-logo" src={logo} alt="logo" />
        </a>
      </Grid>
      <Grid item xs={8} className="app-header">
        <h1>Intake form</h1>
      </Grid>
    </Grid>
  )
}
import React, { forwardRef, useMemo } from 'react';
import PropTypes from 'prop-types';
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
  TextField,
  Checkbox,
  ListSubheader,
  Autocomplete,
} from '@mui/material';
import { Controller } from 'react-hook-form';

export const ConnectedSelectInput = forwardRef(
  (
    {
      name,
      rules,
      label,
      placeholder,
      options,
      required,
      searchable,
      id,
      multiple,
      renderOption = (option) => option,
      renderValue = null,
      displayEmpty,
      fullWidth = true,
      ...props
    },
    ref,
  ) => {
    const labelid = `select-${label}`;

    const optionsFiltered = useMemo(() => {
      return options
        .map((x) => ({
          ...x,
          firstLetter: x.text?.[0]?.toUpperCase(),
        }))
        .sort((a, b) => a.text?.toString().localeCompare(b.text));
    }, [options]);

    const optionsDict = useMemo(() => {
      return options.reduce(
        (a, x) => ({
          ...a,
          [x.value]: x.text,
        }),
        {},
      );
    }, [options]);

    return (
      <Controller
        name={name}
        rules={{
          required,
          ...rules,
        }}
        render={({
          field: { value, onChange, onBlur },
          fieldState: { invalid, error },
        }) => (
          <FormControl
            {...props}
            component='fieldset'
            size='small'
            fullWidth={fullWidth}
            variant='outlined'
            error={invalid}
            required={Boolean(required || rules?.required)}
          >
            {label && !searchable && (
              <InputLabel component='legend' id={labelid}>
                {label}
              </InputLabel>
            )}
            {searchable ? (
              <Autocomplete
                className='flex'
                placeholder={placeholder}
                value={value ?? (multiple ? [] : '')}
                onChange={(e, option) => {
                  onChange(option?.value ?? (multiple ? [] : ''));
                }}
                getOptionLabel={(opt) => optionsDict[opt] || ''}
                getOptionSelected={(option, value) => option.value === value}
                groupBy={(option) => option.firstLetter}
                options={optionsFiltered}
                filterOptions={(options, state) =>
                  options.filter((x) =>
                    x.text
                      .toLowerCase()
                      ?.includes(state.inputValue?.toLowerCase()),
                  )
                }
                ref={ref}
                multiple={multiple}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={label}
                    variant='outlined'
                    size='small'
                    autoFocus={props.autoFocus}
                    onBlur={onBlur}
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: 'new-password', // disable autocomplete and autofill
                    }}
                  />
                )}
                renderOption={(option) => renderOption(option.text, option)}
              />
            ) : (
              <Select
                id={id ?? name}
                placeholder={placeholder}
                labelId={labelid}
                value={value ?? (multiple ? [] : '')}
                onChange={(e) => {
                  onChange(e.target.value);
                }}
                onBlur={onBlur}
                label={label}
                style={{ height: 40 }}
                multiple={multiple}
                renderValue={
                  renderValue ??
                  (multiple
                    ? (selected) => {
                      if (selected?.length > 0) return selected.join(', ');
                      else return null;
                    }
                    : null)
                }
                displayEmpty={displayEmpty}
                ref={ref}
              >
                {options.map((x, i) =>
                  x.subheader ? (
                    <ListSubheader key={i}>
                      {x.subheader}
                    </ListSubheader>
                  ) : (
                    <MenuItem
                      key={x.value}
                      value={x.value}
                      disabled={x.disabled}
                    >
                      {multiple && (
                        <Checkbox checked={value?.indexOf(x?.value) > -1} />
                      )}
                      {renderOption(x.text, x)}
                    </MenuItem>
                  ),
                )}
              </Select>
            )}
            {invalid && <FormHelperText>{error.message}</FormHelperText>}
          </FormControl>
        )}
      />
    );
  },
);

ConnectedSelectInput.propTypes = {
  name: PropTypes.string.isRequired,
  rules: PropTypes.object,
  label: PropTypes.string,
  required: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  disabled: PropTypes.bool,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      text: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
  ),
  searchable: PropTypes.bool,
};

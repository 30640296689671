import {
  ConnectedRadioInput,
  ConnectedSelectInput,
  ConnectedAddressInput,
  yesNoOptions,
  ConnectedNumberInput,
} from 'components/ConnectedInputs';
import {
  InputAdornment
} from '@mui/material';
import { useWatch } from 'react-hook-form';

import '../form.css';

const roofConditionOptions = [
  {
    text: 'Poor',
    value: 'Poor',
  },
  {
    text: 'Fair',
    value: 'Fair',
  },
  {
    text: 'Good',
    value: 'Good',
  },
];

const occupiedOptions = [
  {
    text: 'OCC',
    value: 'OCC',
  },
  {
    text: 'Tennant',
    value: 'Tennant',
  },
  {
    text: 'Vacant',
    value: 'Vacant',
  },
];

const garageType = [
  {
    text: 'Attached',
    value: 'Attached',
  },
  {
    text: 'Detached',
    value: 'Detached',
  }
];

const getNumberOptions = (nr) => {
  let options = [];
  for (let i = 1; i <= nr; i++) {
    options.push({
      text: `${i}`,
      value: `${i}`,
    });
  }
  return options;
}

export const PropertySection = () => {
  const hasGarage = useWatch({name: 'property.hasGarage'});

  return (
    <>
      <h3>Property address</h3>
      <ConnectedAddressInput
        name='property.address'
      />
      <h3>Property size</h3>
      <div className='form-input'>
        <ConnectedNumberInput
          name='property.propertySize'
          label='Property size'
          inputProps={{
            endAdornment: (
              <InputAdornment position='end'>sqft</InputAdornment>
            ),
          }}
        />
      </div>
      <div className='form-input'>
        <ConnectedNumberInput
          name='property.lotSize'
          label='Lot size'
          inputProps={{
            endAdornment: (
              <InputAdornment position='end'>acre</InputAdornment>
            ),
          }}
        />
      </div>
      <div className='form-input'>
        <ConnectedSelectInput
          name='property.bedroomCount'
          label='Number of bedrooms'
          options={getNumberOptions(10)}
        />
      </div>
      <div className='form-input'>
        <ConnectedSelectInput
          name='property.bathroomCount'
          label='Number of full bathrooms'
          options={getNumberOptions(10)}
        />
      </div>
      <div className='form-input'>
        <ConnectedSelectInput
          name='property.halfBathroomCount'
          label='Number of half bathrooms'
          options={getNumberOptions(10)}
        />
      </div>
      <h3>Current condition</h3>
      <div className='form-input'>
        <ConnectedSelectInput
          name='property.roofCondition'
          label='Roof condition'
          options={roofConditionOptions}
        />
      </div>
      <div className='form-input'>
        <ConnectedSelectInput
          name='property.occupation'
          label='Current occupation'
          options={occupiedOptions}
        />
      </div>
      <h3>Features</h3>
      <div className='form-input'>
        <ConnectedRadioInput
          row
          fullWidth={true}
          name='property.hasFinishedBasement'
          label='Does this property have a finished basement?'
          options={yesNoOptions}
        />
      </div>
      <div className='form-input'>
        <ConnectedRadioInput
          row
          name='property.hasAttic'
          label='Does this property have an attic?'
          options={yesNoOptions}
        />
      </div>
      <div className='form-input'>
        <ConnectedRadioInput
          row
          name='property.hasDriveway'
          label='Does this property have a driveway?'
          options={yesNoOptions}
        />
      </div>
      <div className='form-input'>
        <ConnectedRadioInput
          row
          name='property.hasGarage'
          label='Does this property have a garage?'
          options={yesNoOptions}
        />
      </div>
      {hasGarage === 'yes' && (
        <div className='form-input'>
          <ConnectedSelectInput
            name='property.garageType'
            label='Is this garage attached or detached?'
            options={garageType}
          />
        </div>
      )}
    </>
  )
}
import React , {useState, useEffect} from 'react';
import { Button, Box, Tabs, Tab, Container, Backdrop, CircularProgress, Alert } from '@mui/material';
import { getFormData, patchForm, submitForm } from 'actions/formActions';
import { Link, useNavigate, useLocation, useParams, Outlet  } from "react-router-dom"
import { FormProvider, useForm } from 'react-hook-form';
import { Header } from './Header';
import { dirtyValues } from 'util/helper';
import './form.css';

const tabs = {
  property: 'property',
  seller: 'seller',
  agent: 'agent',
  buyer: 'buyer',
  attachments: 'attachments'
}

export const LeadsForm = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const {id} = useParams();
  const [showSubmitMessage, setShowSubmitMessage] = useState(false);
  const [error, setError] = useState();
  const [loading, setLoading] = useState(false);
  const formMethods = useForm({
    mode: 'onChange',
    criteriaMode: 'all',
  });

  // Theres probably a better way to do this
  let selectedTab = (() => {
    const path = location.pathname;
    const tabValues = Object.values(tabs);
    return tabValues.find(tab => path.includes(`/${tab}`)) ?? tabs.property;
  })();

  useEffect(() => {
    (async () => {
      if (id) {
        setLoading(true);
        try {
          const data = await getFormData(id);
          formMethods.reset(data);
        }
        catch (e) {
          setError(e?.message);
        }
        setLoading(false);
      }
    })();
  // eslint-disable-next-line
  }, [id])

  const onError = async (errors) => {
    console.warn(errors);
  };

  const onSave = async (data) => {
    setLoading(true);
    try {
      if (id) {
        const patch = dirtyValues(formMethods.formState.dirtyFields, data);
        await patchForm(id, patch);
      }
      else {
        const result = await submitForm(data);
        navigate(`/${result}/${selectedTab}`);
      }
      setShowSubmitMessage(true);
    }
    catch (e) {
      setError(e?.message);
    }
    setLoading(false);
  };

  const generatePath = (id, path) => {
    return id ? `/${id}/${path}` : `/${path}`;
  }

  const handleGoToAttachments = () => {
    setShowSubmitMessage(false);
    navigate(generatePath(id, 'attachments'));
  }

  const handleGoToNextSection= () => {
    const tabValues = Object.values(tabs);
    const nextIndex = tabValues.findIndex(tab => tab === selectedTab) + 1;
    const nagivateTo = tabValues[nextIndex];
    if (nagivateTo) {
      navigate(generatePath(id, nagivateTo));
    }
    window.scrollTo({top: 0});
  }

  return (    
      <FormProvider {...formMethods}>
        <Container maxWidth="md" >
          <Header />
          {error && (
            <Alert severity="error">There was an error: {error}</Alert>
          )}
          <div className='form'>
            <Backdrop 
              open={loading}
              sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            >
              <CircularProgress color="inherit" />
            </Backdrop>
            {!showSubmitMessage && selectedTab !== 'post-submit' ? (
            <>
              <Tabs
                value={selectedTab}
                variant="scrollable"
                allowScrollButtonsMobile={true}
              >
                <Tab label="Property" value={tabs.property} component={Link} to={generatePath(id, tabs.property)} />
                <Tab label="Seller" value={tabs.seller} component={Link} to={generatePath(id, tabs.seller)} />
                <Tab label="Agent" value={tabs.agent} component={Link} to={generatePath(id, tabs.agent)} />
                <Tab label="Buyer" value={tabs.buyer} component={Link} to={generatePath(id, tabs.buyer)} />
                {id && (
                  <Tab label="Attachments" value={tabs.attachments} component={Link} to={generatePath(id, tabs.attachments)} />
                )}
              </Tabs>
              <Outlet />
            </>
            ) : (
              <>
                <h2>Thank you, we have received your submission</h2>
                <p>Please click <Button onClick={handleGoToAttachments}>here</Button> to upload attachments.</p>
              </>
            )
          }
        </div>
        <Box sx={{display: 'flex', flexDirection: 'row-reverse', mb: 3}}>
          {!showSubmitMessage && selectedTab !== tabs.attachments && (
            <>
                {(id || selectedTab !== tabs.buyer) && (
                  <Button 
                  variant='contained'
                  color='primary'
                  sx={{ml: 3}}
                  onClick={handleGoToNextSection}
                  >
                    Next section
                  </Button>
              )}
              <Button 
                disabled={!formMethods.formState.isDirty}
                variant='contained'
                color='success'
                onClick={formMethods.handleSubmit(onSave, onError)}
              >
                {id ? 'Update form' : 'Submit form'}
              </Button>
            </>
            )}
          </Box>
        </Container>
      </FormProvider>
  )
}